export function Validations(value, validationType, country) {
  switch (validationType) {
    case "dataPolicy":
      return [value, value === "Sí"];
    case "name":
      const namevalidation = new RegExp("^[A-Za-zÀ-ÖØ-öø-ÿs\\s]+$");
      return [value, namevalidation.test(value)];

    case "email":
      const emailValidation = new RegExp(
        "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
      );
      return [value, emailValidation.test(value)];
    case "document":
      const documentValidation = new RegExp("^[0-9]{7,12}$");
      return [value, documentValidation.test(value.toString())];
    case "phone":
      const phoneValidation = new RegExp("^[0-9]{7,10}$");
      return [value, phoneValidation.test(value.toString())];
    case "incomes":
      if (country == "Colombia" && value && value >= 1000000) {
        return [value, true];
      } else if (country == "Estados Unidos" && value && value >= 1000) {
        return [value, true];
      }
      return [value, false];
    case "rent":
      if (value | (value === 0)) {
        return [value, true];
      }
      return [value, false];
    case "place":
      return [value, (value === "Sí") | (value === "No")];
    case "homeCost":
    case "neighborhood":
    case "currentStatus":
    case "logo":
    case "photos":
    case "agent":
    case "address":
      if (value) {
        return [value, true];
      }
      return [value, false];
    case "cost":
      if (value) {
        if (value >= 100000000 && value <= 900000000) {
          return [value, true];
        }
      }
      return [value, false];
    case "saving":
      if ((value === 0) | value) {
        return [value, true];
      }
      return [value, false];
    case "propertyUrl":
      const urlValidation = new RegExp(
        "^(?:(?:https?|ftp):\\/\\/)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?)(?::\\d{2,5})?(?:[/?#]\\S*)?$"
      );
      if (!value) {
        return [value, true];
      } else {
        return [value, urlValidation.test(value) | (value === "")];
      }
    case "savingCapacity":
    case "propertyConstructionStatus":
    case "employmentStatus":
    case "persons":
    case "country":
    case "city":
    case "upz":
    case "economic_class":
    case "floor":
    case "parking":
    case "construction_date":
    case "rooms":
    case "bathrooms":
    case "private_area":
    case "living_area":
      if ((value === 0) | (value === "0")) {
        return [value, false];
      } else {
        return [value, true];
      }
    case "wasReferred":
      if ((value === 0) | (value === "0") | (value === "")) {
        return [value, false];
      } else {
        return [value, true];
      }
    default:
      return [value, false];
  }
}

export function emptyValidations(currentPage, formData) {
  const defaultOrder = {
    0: "dataPolicy",
    1: "name",
    2: "country",
    3: "email",
    4: "document",
    5: "phone",
    6: "persons",
    7: "incomes",
    8: "rent",
    9: "employmentStatus",
    10: "savingCapacity",
    11: "place",
    12: "city",
    13: "neighborhood",
    14: "propertyUrl",
    15: "propertyConstructionStatus",
    16: "cost",
    17: "saving",
    18: "currentStatus",
    19: "was_referred",
  };
  const numberType = [7, 8, 11];
  const freeValidation = 14;
  if (currentPage === freeValidation) {
    return false;
  }
  if (formData[defaultOrder[currentPage]] !== "") {
    if (
      numberType.includes(defaultOrder[currentPage]) &
      !formData[defaultOrder[currentPage]] &
      (formData[defaultOrder[currentPage]] !== 0)
    ) {
      return true;
    }
    return false;
  }
  return true;
}

export function emptyValidationsSell(currentPage, formData) {
  const defaultOrder = {
    0: "dataPolicy",
    1: "agent",
    2: "logo",
    3: "city",
    4: "upz",
    5: "address",
    6: "type",
    7: "cost",
    8: "administration",
    9: "economic_class",
    10: "floor",
    11: "parkings",
    12: "construction_date",
    13: "rooms",
    14: "bathrooms",
    15: "private_area",
    16: "living_area",
    17: "description",
    18: "amenities",
    19: "photos",
  };
  const numberType = [6, 7, 12];
  const freeValidation = [3, 18];
  if (currentPage === freeValidation) {
    return false;
  }
  if (formData[defaultOrder[currentPage]] !== "") {
    if (
      numberType.includes(defaultOrder[currentPage]) &
      !formData[defaultOrder[currentPage]] &
      (formData[defaultOrder[currentPage]] !== 0)
    ) {
      return true;
    }
    return false;
  }
}
