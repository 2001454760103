import axios from "axios";
import React from "react";

export class successForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    this.cityEndpoint = apiUrl + "/city/id";
    this.clientEndpoint = apiUrl + "/clients/";
    this.internalApplicationEndpoint = apiUrl + "/marketplace_application/";
    this.externalApplicationEndpoint = apiUrl + "/external_application/";
  }
  updateData(data) {
    let transformDict = {
      document_number:
        Math.floor(Math.random() * (1000000 - 100000000)) + 1000000,
      cellphone: data["phone"],
      roommates: data["persons"],
      monthly_income: data["incomes"],
      monthly_rent: data["rent"],
      property_neighborhood: data["neighborhood"],
      property_cost: data["cost"],
      property_construction_status: data["propertyConstructionStatus"],
      down_payment: data["saving"],
      data_policy_acceptance: true,
      country: data["country"],
      place: data["place"],
    };
    let clientData = Object.assign(transformDict, data);
    delete clientData.dataPolicy;

    return clientData;
  }
  async putClient(completeData) {
    this.down_payment = completeData.down_payment;
    let clientData = {
      name: completeData.name,
      email: completeData.email,
      document_number: completeData.document_number,
      cellphone: completeData.cellphone,
      roommates: completeData.roommates,
      monthly_income: parseInt(completeData.monthly_income),
      monthly_rent: parseInt(completeData.monthly_rent),
      data_policy_acceptance: completeData.data_policy_acceptance,
      monthly_savings: completeData.savingCapacity,
      moving_situation: completeData.currentStatus,
      job_situation: completeData.employmentStatus,
    };
    console.log(clientData);
    const propId = await axios
      .put(this.clientEndpoint, clientData)
      .then((response) => {
        this.clientID = response.data.id;
      });
    return propId;
  }
  async putApplication(data) {
    if (parseInt(data["property_id"])) {
      let marketplace_application = {
        client_id: this.clientID,
        property_id: data.property_id,
        down_payment: this.down_payment,
        country: data.country,
        place: data.place,
        was_referred: data.wasReferred,
      };
      await axios
        .put(this.internalApplicationEndpoint, marketplace_application)
        .then((response) => {
          console.log(response);
        });
    } else {
      let external_application = {};
      if (data.city === "Bogotá y alrededores") {
        data.city = "Bogotá";
      } else if (data.city === "Medellín y alrededores") {
        data.city = "Medellín";
      }
      if (data.city) {
        const cityId = await axios.get(
          this.cityEndpoint + "?city_name=" + data.city
        );
        external_application = {
          client_id: this.clientID,
          country: data.country,
          property_cost: data.property_cost,
          property_construction_status: data.property_construction_status,
          property_city_id: cityId.data.id,
          down_payment: this.down_payment,
          property_neighborhood: data.property_neighborhood,
          property_url: data.propertyUrl,
          place: data.place,
          was_referred: data.wasReferred,
        };
      } else {
        external_application = {
          client_id: this.clientID,
          country: data.country,
          property_cost: data.property_cost,
          property_construction_status: data.property_construction_status,
          down_payment: this.down_payment,
          place: data.place,
          was_referred: data.wasReferred,
        };
      }
      //console.log(external_application)
      await axios
        .put(this.externalApplicationEndpoint, external_application)
        .then((response) => {
          //console.log(response)
        });
    }
  }
  async main(data) {
    const completeData = this.updateData(data);
    await this.putClient(completeData);
    await this.putApplication(completeData);
    window.location.href = "/success";
  }
}
