import React, { useState } from "react";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Whatsapp } from "./Components/Generals/Whatsapp";
import { FaqPage } from "./Components/Pages/FAQ/FaqPage";
import { MultipageForm as MForm } from "./Components/Pages/Form/Apply/MultipageForm";
import { FormInvestments } from "./Components/Pages/Form/Investments/FormInvestments";
import { MultipageFormSell as MFormSell } from "./Components/Pages/Form/Sell/MultipageFormSell";
import NotFound from "./Components/Pages/NotFound";
import { Personaldata } from "./Components/Pages/Personaldata";
import { Success } from "./Components/Pages/Success";
import { Tyc } from "./Components/Pages/Tyc";
import { StoreProvider } from "./utils/store";
import { Navbarcomp } from "./base_styles/components/navbar/Navbarcomp";
import { Footer } from "./base_styles/components/footer/Footer";

const tagManagerArgs = {
  gtmId: "GTM-NCJ2P643",
};

TagManager.initialize(tagManagerArgs);

const Marketplace = React.lazy(() => import("./Components/Pages/Marketplace"));
const Property = React.lazy(() => import("./Components/Pages/Property"));

export function App() {
  /* const [invert, setInvert] = useState(true); */
  const [successpage, setSuccesspage] = useState(false);
  const [nonavpage, setNonavpage] = useState(false);
  const [bgfooter, setBgfooter] = useState("bg-cream");
  const [pathname, setPathname] = useState("/");
  window.onload = function () {
    let pathname = window.location.pathname;
    setPathname(window.location.pathname);
    if (pathname === "/") setBgfooter("bg-light-purple");
    else if (pathname === "/calculator") setBgfooter("bg-white");
    else if (
      pathname === "/faq" ||
      pathname === "/marketplace" ||
      pathname === "/login" ||
      pathname === "/team" ||
      pathname === "/investor" ||
      pathname.includes("investment") ||
      pathname.includes("property")
    )
      setBgfooter(" ");
    pathname.indexOf("/form") === 0 ||
    pathname === "/login" ||
    pathname === "/success"
      ? setNonavpage(true)
      : setNonavpage(false);
    window.location.pathname === "/success"
      ? setSuccesspage(true)
      : setSuccesspage(false);
  };
  return (
    <StoreProvider>
      <Navbarcomp nonavpage={nonavpage} />
      <React.Suspense>
        <main id="main-component">
          <Router>
            <Routes>
              <Route
                path="/property/:property_id/:min_principal_payment/"
                element={<Property />}
              />
              <Route path="/faq" element={<FaqPage />} />
              <Route path="/tyc" element={<Tyc />} />
              <Route path="/personal-data" element={<Personaldata />} />
              <Route path="/success" element={<Success />} />
              <Route
                path="/form/:form_type/:property_id/"
                element={<MForm />}
              />
              <Route path="/upload" element={<MFormSell />} />
              <Route path="/investments/upload" element={<FormInvestments />} />
              <Route path="/" element={<Marketplace />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
        </main>
      </React.Suspense>
      <Footer nonavpage={nonavpage} successpage={successpage} />
      <Whatsapp />
    </StoreProvider>
  );
}
