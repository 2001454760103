import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { emptyValidations, Validations } from "../FormValidations";
import { Input } from "../Input";
import { successForm } from "./SubmitData";

function component(formData, handleFormData, page) {
  switch (page) {
    case 0:
      return (
        <Input
          title={<h1 className="bolder">¡Bienvenido!</h1>}
          info="Completar el siguiente formulario es el primer paso para aplicar al Programa de Vivienda Toperty: compra tu casa mientras la alquilas."
          inputLabel={
            <>
              <span className="fs-5">
                Antes de comenzar ¿Aceptas nuestra
                <a
                  className="ms-1 underlined fs-5"
                  href="https://toperty.co/politica-privacidad"
                >
                  Política de tratamiento de datos?
                </a>
              </span>
            </>
          }
          inputKey="dataPolicy"
          inputValue={formData["dataPolicy"]}
          formData={formData}
          handleFormData={handleFormData}
          inputType="radio"
          radio={["Sí", "No"]}
        />
      );
    case 1:
      return (
        <Input
          inputLabel={<b className="fs-5">¿Cómo es tu nombre y apellido?</b>}
          inputType="text"
          inputId="name-in"
          inputKey="name"
          inputValue={formData["name"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 2:
      return (
        <Input
          inputLabel={<b className="fs-5">País de residencia </b>}
          inputType="select"
          inputId="country-in"
          inputKey="country"
          select={["Colombia", "Estados Unidos"]}
          inputValue={formData["country"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 3:
      return (
        <Input
          inputLabel={<b className="fs-5">¿Cómo es tu email?</b>}
          inputType="mail"
          inputId="email-in"
          inputKey="email"
          inputValue={formData["email"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 4:
      return (
        <Input
          inputLabel={<b className="fs-5">¿Cuál es tu número de documento?</b>}
          inputType="text"
          inputId="document-in"
          inputKey="document"
          inputValue={formData["document"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 5:
      return (
        <Input
          inputLabel={<b className="fs-5">¿Cuál es tu número de celular?</b>}
          inputType="text"
          inputId="phone-in"
          inputKey="phone"
          inputValue={formData["phone"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 6:
      return (
        <Input
          inputLabel={
            <>
              <b className="fs-5">
                ¿Aplicarás solo o con alguien más que aporte ingresos?"
              </b>
              <p className="fs-5" style={{ fontWeight: "normal" }}>
                (Puedes incluir un co-aplicante que viva contigo. Al sumar tus
                ingresos con los de esta persona, aumentan tus posibilidades de
                calificar al programa.)
              </p>
            </>
          }
          inputType="select"
          inputId="persons-in"
          inputKey="persons"
          select={["Aplico solo", "Aplico con alguien más que aporta ingresos"]}
          inputValue={formData["persons"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 7:
      return (
        <Input
          inputType="money"
          inputLabel={
            <>
              <b className="fs-5">
                ¿Cuál es el ingreso mensual total de todas las personas que
                están aplicando?
              </b>
              <p className="fs-5" style={{ fontWeight: "normal" }}>
                (Si aplicas solo, incluye únicamente tus ingresos. Si aplicas
                con alguien más, suma sus ingresos a los tuyos).
              </p>
            </>
          }
          inputKey="incomes"
          inputId="incomes-in"
          inputValue={formData["incomes"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 8:
      return (
        <Input
          inputType="money"
          inputLabel={
            <>
              <b className="fs-5">¿Cuánto pagas al mes en arriendo?</b>
              <p className="fs-5" style={{ fontWeight: "normal" }}>
                (Incluyendo administración)
              </p>
            </>
          }
          inputKey="rent"
          inputId="rent-in"
          inputValue={formData["rent"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 9:
      return (
        <Input
          inputLabel={
            <>
              <b className="fs-5">¿Cuál es tu situación laboral?</b>
              <p className="fs-5" style={{ fontWeight: "normal" }}>
                (De la persona del hogar que tiene los mayores ingresos.)
              </p>
            </>
          }
          inputType="select"
          inputId="employmentStatus-in"
          inputKey="employmentStatus"
          select={[
            "Contrato a término fijo",
            "Contrato a término indefinido",
            "Independiente",
          ]}
          inputValue={formData["employmentStatus"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 10:
      return (
        <Input
          inputType="select"
          inputLabel={
            <b className="fs-5">
              ¿Cuál es la situación laboral de la persona que aplica contigo?
            </b>
          }
          inputKey="savingCapacity"
          inputId="savingCapacity-in"
          select={[
            "aplico solo",
            "Aplico con un empleado con contrato laboral",
            "Aplico con una persona independiente",
          ]}
          inputValue={formData["savingCapacity"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 11:
      return (
        <Input
          inputType="radio"
          inputLabel={
            <b className="fs-5">
              ¿Ya tienes vista la casa que quieres comprar?
            </b>
          }
          inputKey="place"
          radio={["Sí", "No"]}
          inputId="place-in"
          inputValue={formData["place"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 12:
      return (
        <Input
          inputType="select"
          inputLabel={<b className="fs-5">¿En qué ciudad quieres comprar?</b>}
          inputKey="city"
          select={[
            "Bogotá y alrededores",
            "Medellín y alrededores",
            "Cali",
            "Bucaramanga",
            "Pereira",
            "Armenia",
            "Manizales",
            "Cartagena",
            "Barranquilla",
            "Ibagué",
            "Santa Marta",
            "Cúcuta",
          ]}
          inputId="city-in"
          inputValue={formData["city"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 13:
      return (
        <Input
          inputType="text"
          inputLabel={<b className="fs-5">¿En qué barrio está ubicada?</b>}
          inputKey="neighborhood"
          inputId="neighborhood-in"
          inputValue={formData["neighborhood"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 14:
      return (
        <Input
          inputType="text"
          inputLabel={
            <>
              <b className="fs-5">
                Copia y pega el link de la inmobiliaria donde está publicada tu
                vivienda.
              </b>
              <p className="fs-5" style={{ fontWeight: "normal" }}>
                (Opcional, en caso de que lo tengas)
              </p>
            </>
          }
          inputKey="propertyUrl"
          inputId="propertyUrl-in"
          inputValue={formData["propertyUrl"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 15:
      return (
        <Input
          inputLabel={
            <>
              <b className="fs-5">
                Escoge el tipo de vivienda que quieres comprar:
              </b>
            </>
          }
          inputType="select"
          inputId="propertyConstructionStatus-in"
          inputKey="propertyConstructionStatus"
          select={[
            "Vivienda usada",
            "Vivienda nueva que ya está construída",
            "Vivienda nueva que terminará de ser construída dentro de los próximos 6 meses",
            "Vivienda nueva que terminará de ser construída después de los próximos 6 meses",
          ]}
          inputValue={formData["propertyConstructionStatus"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 16:
      return (
        <Input
          inputType="money"
          inputLabel={
            <>
              <b className="fs-5">
                ¿Cuánto cuesta la vivienda que quieres comprar?
              </b>
              <p className="fs-5" style={{ fontWeight: "normal" }}>
                (Recuerda que Toperty aporta hasta el 90% de este valor. Tu
                debes aportar al menos el 10% como cuota inicial. Por ejemplo,
                para una vivienda de $200.000.000, Toperty aporta $180.000.000 y
                tu $20.000.000.)
              </p>
            </>
          }
          inputKey="cost"
          inputId="cost-in"
          inputValue={formData["cost"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 17:
      return (
        <Input
          inputType="money"
          inputLabel={
            <>
              <b className="fs-5">
                ¿Cuántos ahorros tienes para la cuota inicial?
              </b>
              <p className="fs-5" style={{ fontWeight: "normal" }}>
                Recuerda que el programa de Toperty exige que tengas al menos el
                10% del valor del inmueble. También, en la medida que aportes
                una mayor cuota inicial puedes reducir la duración del programa
                y lo que pagas de cuotas mensual.
              </p>
            </>
          }
          placeholder="Escribe un valor en pesos"
          inputKey="saving"
          inputId="saving-in"
          inputValue={formData["saving"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 18:
      return (
        <Input
          inputType="radio"
          inputLabel={
            <b className="fs-5">¿Cuándo planeas mudarte a tu nuevo hogar?”</b>
          }
          inputKey="currentStatus"
          radio={[
            "Corto plazo: 0 a 4 meses",
            "Mediano plazo: 4 a 8 meses",
            "Largo plazo: 8 a 12 meses",
          ]}
          inputId="currentStatus-in"
          inputValue={formData["currentStatus"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 19:
      return (
        <Input
          inputLabel={
            <>
              <b className="fs-5">
                ¿Vienes referido de alguna inmobiliaria, contstrutora, o de
                algún aliado, o cliente actual?
              </b>
            </>
          }
          inputType="select"
          inputId="wasReferred-in"
          inputKey="wasReferred"
          select={[
            "Holding Inmuebles",
            "Coninsa",
            "Cliente de Toperty",
            "Entrega Inmobiliaria",
            "Isarco /Tinguazul",
            "Ninguno",
          ]}
          inputValue={formData["wasReferred"]}
          formData={formData}
          handleFormData={handleFormData}
        />
      );
    case 20:
      return (
        <Input
          title={
            <h1 className="bolder">
              Enviando datos
              <div className="loading-spinner-container">
                <div className="loading-spinner"></div>
              </div>
            </h1>
          }
        />
      );
    default:
      return <h1>Hubo un problema al cargar la vista</h1>;
  }
}
export function MultipageForm() {
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const propertyEndPoint = apiUrl + "/property/specific_property_detail";
  const [propData, setPropData] = useState(0);
  let successform = new successForm();
  function handleSubmit() {
    setValidate(true);
  }

  function handleBackPage() {
    if (!inputErrorStatus) {
      let form_type = params.form_type;
      if (form_type === "1" && page === 17) {
        setPage(8);
      } else if (formData.place === "No" && page === 15) {
        setPage(11);
      } else if (page === 5) {
        setPage(2);
      } else {
        setPage(page - 1);
      }
    } else {
      alert("Debes corregir el error antes de ir atrás.");
    }
  }
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        document.getElementById("NextBtn").click();
      }
    };
    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);
  const [formData, setFormData] = useState({
    dataPolicy: "",
    name: "",
    country: "",
    email: "",
    document: 1000000000,
    phone: "",
    persons: "",
    incomes: "",
    savingCapacity: "",
    rent: "",
    employmentStatus: "",
    place: "",
    city: "",
    neighborhood: "",
    propertyUrl: "",
    propertyConstructionStatus: "",
    cost: "",
    saving: "",
    currentStatus: "",
    lastModified: "",
    wasReferred: "",
  });
  const errorMessages = {
    emptyValue: "El campo es requerido.",
    dataPolicy: "Debes aceptar nuestra política de datos para continuar.",
    name: "El nombre no puede contener números.",
    email:
      "La estructura recibida no coincide con la de un correo electrónico.",
    document:
      "El número de identificación debe estar conformado únicamente por números y debe contener al menos siete dígitos",
    phone: "No debe contener letras y tienen que ser 7 o 10 números",
    persons: "Selecciona una opción válida para continuar.",
    incomes:
      "El campo es requerido, y el valor mínimo es $1.000.000 verifica los datos ingresados.",
    savingCapacity: "Selecciona una opción válida para continuar.",
    rent: "El campo es requerido, Verifica los datos ingresados.",
    employmentStatus: "Selecciona una opción válida para continuar.",
    place: "Selecciona una opción válida para continuar.",
    city: "Selecciona una opción válida para continuar.",
    neigborhood: "El campo es requerido, Verifica los datos ingresados.",
    propertyUrl: "Ingresa un enlace válido.",
    propertyConstructionStatus: "Selecciona una opción válida para continuar.",
    cost: "El valor del inmueble debe ser de al menos 100 millones de pesos y máximo 900 millones.",
    saving: "El campo es requerido, Verifica los datos ingresados.",
    currentStatus: "Selecciona una opción válida para continuar.",
    wasReferred: "Selecciona una opción válida para continuar.",
  };
  const handleFormData = (k, v) => {
    if (k)
      setFormData({
        ...formData,
        [k]: v,
        lastModified: k,
      });
    console.log(formData);
  };
  const params = useParams();

  useEffect(() => {
    async function getPropData() {
      await axios
        .get(propertyEndPoint + "?prop_id=" + params.property_id)
        .then((response) => {
          setPropData(response.data);
        });
    }
    if (params.property_id & (params.property_id !== 0)) {
      getPropData();
    }
  }, [params]);

  if (!formData.property_id & (params.property_id !== 0)) {
    setFormData({
      ...formData,
      property_id: params.property_id,
      cost: propData.cost,
    });
  }
  const [page, setPage] = useState(0);
  const [validate, setValidate] = useState(false);
  const [emptyValidation, setEmptyValidation] = useState(false);
  const [inputErrorStatus, setInputErrorStatus] = useState(false);

  useEffect(() => {
    if (params.property_id) {
    }
    if ((page === 0) & !validate) {
      setFormData({
        ...formData,
        dataPolicy: "",
        lastModified: "dataPolicy",
      });
    }
    if (validate) {
      let results = Validations(
        formData[formData["lastModified"]],
        formData["lastModified"],
        formData["country"]
      );
      console.log(results);
      if (!results[1]) {
        setInputErrorStatus(true);
        setValidate(false);
        setEmptyValidation(false);
        return;
      } else {
        let emptyResult = emptyValidations(page, formData);
        setEmptyValidation(emptyResult);
        setValidate(false);
        setInputErrorStatus(false);
        if (emptyResult) return;
      }
      let form_type = params.form_type;
      if (form_type === "1" && page === 9) {
        setPage(17);
      } else if (formData.place === "No" && page === 12) {
        setPage(15);
      } else if (page === 3) {
        setPage(5);
      } else {
        setPage(page + 1);
        console.log(page);
      }

      setValidate(false);
      setInputErrorStatus(false);
      setEmptyValidation(false);
    }
    setTimeout(() => {
      if (page === 20) {
        successform.main(formData);
      }
    }, 2000);
  }, [validate]);

  return (
    <Container fluid className="mform-container">
      <Row className="header"></Row>
      <Row className="justify-content-center content">
        <Col md={6} xs={10}>
          <div className="logo mb-4"></div>
          {page >= 0 && (
            <button
              className="btn-transparent-grey mb-4"
              onClick={handleBackPage}
              disabled={page === 0 || page === 20}
            >
              ← Atrás
            </button>
          )}
          <div className="component">
            {component(
              formData,
              handleFormData,
              page,
              params.form_type,
              params.property_id
            )}
          </div>
          <div className="error-msg">
            {inputErrorStatus && (
              <p>{errorMessages[formData["lastModified"]]}</p>
            )}
            {emptyValidation && <p>{errorMessages["emptyValue"]}</p>}
          </div>
          {page !== 20 ? (
            <button
              className="btn-rounded-green mt-3"
              id="NextBtn"
              onClick={handleSubmit}
            >
              {page >= 0 && page <= 18 ? "Siguiente" : "Enviar"} →
            </button>
          ) : (
            <div className="submission-animation mt-3">
              <div className="loading-spinner-container">
                <div className="loading-spinner"></div>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}
